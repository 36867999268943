.autocomplete-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.autocomplete-container:first-child {
  flex: 1;
}

.item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.analytics-container {
  width: 60%;
}

.item-wrapper {
  display: flex;
  justify-content: space-between;
}

.item-title {
  padding-right: 35px;
}

.item-status {
  align-items: center;
  display: flex;
}
