.ribbon {
  width: 140px;
  height: 25px;
  font-size: 11px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  box-shadow: 0px 2px 3px rgb(136 136 136 / 25%);
  background: #4dbe3b;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  right: -42px;
  top: 20px;
  padding-top: 3px;
}
