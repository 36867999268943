.campaign-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.campaign-item img {
  max-width: 350px;
  margin: 0 0 20px 70px;
}

.campaign-title {
  color: #0c3450;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
}
