.tag-preview {
  background-color: #e5e5e5;
  border-radius: 3px;
  padding: 3px;
  cursor: pointer;
}

.rta__autocomplete {
  z-index: 9999;
}

.rta__autocomplete .rta__entity div {
  padding: 5px 9px;
  font-size: 14px;
}

.rta__autocomplete .rta__entity--selected {
  background: #fc5085;
}

.tagarea,
.backdrop,
textarea {
  width: 100%;
  height: 230px;
}

.highlights,
textarea {
  padding: 10px;
  font: 14px/28px 'Open Sans', sans-serif;
  letter-spacing: 1px;
}

.tagarea {
  display: block;
  /*margin: 0 auto;*/
  transform: translateZ(0);
  -webkit-text-size-adjust: none;
  margin-top: 15px;
  margin-bottom: 5px;
}

.tagarea-wrapper {
  margin-bottom: 30px;
}

.available-tags button {
  margin-right: 5px;
  margin-top: 3px;
  margin-bottom: 5px;
  padding: 3px 5px;
  color: #637381;
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  cursor: pointer;
}

.backdrop {
  position: absolute;
  z-index: 1;
  border: 1px solid #d0d0d0;
  background-color: #fff;
  overflow: auto;
  pointer-events: none;
  transition: transform 1s;
  border-radius: 3px;
}

.highlights {
  white-space: pre-wrap;
  word-wrap: break-word;
  color: transparent;
}

textarea {
  display: block;
  position: absolute;
  z-index: 2;
  margin: 0;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  color: #637381;
  background-color: transparent;
  overflow: auto;
  resize: none;
  transition: transform 1s;
  font-size: 14px !important;
}

mark {
  border-radius: 5px;
  color: transparent;
  background-color: #e5e5e5;
  padding: 3px 0;
}

textarea:focus,
button:focus {
  outline: none;
  /*box-shadow: 0 0 0 2px #c6aada;*/
}
