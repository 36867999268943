.indexedStep {
  color: white;
  width: 30px;
  height: 30px;
  font-size: 12px;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indexedStep.accomplished {
  /* background-color: rgba(0, 116, 217, 1); */
  background-color: #47c1bf;
}

.indexedLabel {
  position: absolute;
  top: 35px;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}

.RSPBprogressBar {
  margin-left: 15px;
}
